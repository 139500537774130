import { Mapper } from './types'

export const fromEntries = <T = any>(entries: [string, any][]) =>
  entries.reduce((sum, [k, v]) => ({ ...sum, [k]: v }), {} as T)

export const tupleOf = <T, P>(t: T, p: P): [T, P] => [t, p]

export const topicMapper = (d: any) => ({
  id: d.video_id,
  title: d.video_title,
  link: d.link,
  timestamp: d.time_stamp * 1000,
  dateString: d.date_string,
  tags: d.tags,
  description: d.description,
  jumpLink: d.jump_link,
  md5: d.md5,
  lawyer:
    d.lawyer_id == null
      ? undefined
      : {
        id: d.lawyer_id,
        name: d.lawyer_name,
        lawfirm: d.law_firm,
        photo: d.photo,
        link: d.profile_link,
        intro: d.lawyer_intro,
        website: d.website,
      },
})

export const noop = () => {
}

export const fillZero = (num: string | number, length: number): string => {
  const arr = Array(length).fill(0);
  [].forEach.call(`${num}`, (n, i, numArr) => arr[length - i - 1] = numArr[numArr.length - i - 1])
  return arr.join('')
}

export const mmddyyyyFormatter = (dateString: string, originSep: string = '/') =>
  dateString.split(originSep).map((item, i) => i < 2 ? fillZero(item, 2) : item).join('-')

// export const chineseDateFormatter = (dateString: string, originSep: string = '/') =>
//   dateString.split(originSep).map((item, i) => i < 2 ? fillZero(item, 2) : item).join('-')

export const onMobile = `@media only screen and (max-width: 767px)`

export const onTablet = `@media only screen and (min-width: 768px)`

export const mapWith = <T>(mappers: Mapper<T>[]) => (arr: T[]) =>
  mappers.reduce((val, m) => m(val), arr)

export const isPresent = str => str && str !== 'nan'
