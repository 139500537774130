import React from 'react'
import arrow from './resource/arrow.svg'
import email from './resource/email.svg'
import location from './resource/location.svg'
import phone from './resource/phone.svg'
import share from './resource/share.svg'
import thumbsUp from './resource/thumbs-up.svg'
import tag from './resource/tag.svg'
import website from './resource/website.svg'
import largeLogo from './resource/large-logo.svg'
import smallLogo from './resource/small-logo.svg'
import miniLogo from './resource/mini-logo.svg'
import blackTextLogo from './resource/black-text-logo.svg'
import wechat from './resource/wechat.svg'
import youtube from './resource/youtube.svg'
import zhihu from './resource/zhihu.svg'
import filter from './resource/filter.svg'
import search from './resource/search.svg'
import sort from './resource/sort.svg'
import confirmed from './resource/confirmed.svg'
import question from './resource/question.svg'
import quote from './resource/quote.svg'
import refresh from './resource/refresh.svg'
import refreshBlack from './resource/refresh-black.svg'
import calendar from './resource/calendar.svg'

const resource = {
  arrow,
  email,
  location,
  phone,
  share,
  thumbsUp,
  tag,
  website,
  largeLogo,
  smallLogo,
  miniLogo,
  blackTextLogo,
  wechat,
  youtube,
  zhihu,
  filter,
  search,
  sort,
  confirmed,
  question,
  quote,
  refresh,
  refreshBlack,
  calendar,
}

export type IconName =
  | 'arrow'
  | 'email'
  | 'location'
  | 'phone'
  | 'share'
  | 'thumbsUp'
  | 'tag'
  | 'website'
  | 'largeLogo'
  | 'smallLogo'
  | 'miniLogo'
  | 'wechat'
  | 'youtube'
  | 'zhihu'
  | 'filter'
  | 'search'
  | 'sort'
  | 'confirmed'
  | 'question'
  | 'quote'
  | 'refresh'
  | 'refreshBlack'
  | 'calendar'
  | 'blackTextLogo'

interface Props {
  name: IconName
}

export default function Icon({ name, ...props }: Props) {
  return <img {...props} src={resource[name]}/>
}
