import { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  body {
    background: #f2f2f2;
    font-family: "Microsoft YaHei", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }

  body,
  header,
  footer,
  div,
  main {
    display: flex;
    flex-direction: column;
  }

  button {
    appearance: none;
    border: none;
    outline: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const touchable = css`
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
`
export default GlobalStyle
